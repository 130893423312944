import React from 'react'
import '../Components/CSS/Projects.css'
import borimg from '../Components/Images/bwi.jpg'
import schoolgirl from '../Components/Image/zp-school.jpg'

const Projects = () => {
  return (
    <div>
        <div className='project-session1'>
            <div className='project-bar3'>
                <b>2B</b>
                <span>Safely managed drinking water services are lacking for people across the globe</span>
            </div>
            <div className='project-bar3'>
                <b>10</b>
                <span>Safely managed drinking water services remain inaccessible to people globally</span>
            </div>
            <div className='project-bar3'>
                <b>150</b>
                <span>The global population lacks access to drinking water services that are safely managed</span>
            </div>

        </div>
        <div className='project-session2'>
            <div className='project-latur'>
            <h3>Latur Village - Maharashtra State, India</h3>
            <span>Project completed by WellAware in October 2020</span>
            <p>Latur Village in Maharashtra, India, witnessed a transformative project completed by WellAware in October 2020, with generous support from AquaBharat. The community of Atot anticipates a significant population increase, ushering in substantial lifestyle changes. The introduction of clean water has sparked enthusiasm within the community, fostering the establishment of gardens and a collective commitment to enhancing overall health. The newfound accessibility to clean water holds the promise of liberating women from the arduous task of water fetching, empowering them to participate in small businesses and facilitate their children's education. This positive shift is expected to contribute to a reduction in nomadic practices, as the community becomes more settled. Moreover, the enhanced water availability is poised to foster peace and diminish conflicts with neighboring communities, marking a significant improvement in the overall well-being of Latur Village. WellAware and AquaBharat celebrate the success of this impactful project, bringing positive change to the lives of the residents of Latur Village.8</p>
            </div>
            <div className='project-latur-image'>
                <img src={borimg} className='bor-img'/>
            </div>
          
        </div>
        <div className='project-session3'>
        <div className='project-school'>
            <h3>Deshikendra Primary School - Latur, Maharashtra</h3>
            <span>Project Completed by Wellware in Octomber 2020</span>
            <p>In Latur, Maharashtra, WellAware completed a project at Deshikendra Primary School in October 2020. Before the intervention, Deborah and her 350 peers relied on a contaminated river and well for water. WellAware implemented a large-scale rainwater solution, finished in December 2019. Typically, WellAware projects lead to a 34% increase in school attendance post-clean water availability. The positive impact of this initiative is expected to unlock new possibilities for Deborah and her classmates, fostering a healthier and more conducive learning environment. WellAware eagerly awaits the remarkable achievements these children will accomplish with improved access to clean water.</p>
            </div>
            <div className='project-school-image'>
                <img src={schoolgirl} className='school-girl-img'/>
            </div>

        </div>
      
    </div>
  )
}

export default Projects