import React, { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import '../Components/CSS/Navbar.css';
import aqua from './Images/ak.jpg';
import { NavLink } from "react-router-dom";

function Navbar() {
    const navRef = useRef();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const closeNavbar = () => {
        navRef.current.classList.remove("responsive_nav");
        setIsNavOpen(false);
    };

    const toggleNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
        setIsNavOpen(!isNavOpen);
    };

    return (
        <header>
            <div className="logo-container">
                <NavLink to={"/"} onClick={closeNavbar}><img src={aqua} alt="logo" className="logo" /></NavLink>
            </div>
          
            <div className="menu-icon" onClick={toggleNavbar}>
                {isNavOpen ? <FaTimes className="false"/> : <FaBars className="bar"/>} 
            </div>

            <nav ref={navRef}>
                <NavLink to={"/"} onClick={closeNavbar}>Home</NavLink>
                <NavLink to={"/advertise"} onClick={closeNavbar}>Adverties</NavLink>
                <NavLink to={"/dist"} onClick={closeNavbar}>Distribute</NavLink>
                <NavLink to={"/faq"} onClick={closeNavbar}>FAQ</NavLink>
                <NavLink to={"/contact"} onClick={closeNavbar}>Contact Us</NavLink>
            </nav>
        </header>
    );
}

export default Navbar;
