import React from 'react'
import './CSS/Footer.css'
import { Link } from 'react-router-dom'
import { SiTiktok } from "react-icons/si";
import { FiInstagram } from "react-icons/fi";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsReddit } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
import { ImFacebook } from "react-icons/im";

import facbk from "./Images/fc1-bcrem.png";
import { GoChevronRight } from "react-icons/go";


const Footer = () => {
  return (
    <div>
      

      <footer>
                <div className='footers'>

                    <div className='f-content'>
                        <h7>Aqua should be accessible without cost.</h7>
                        <span className='help-us'>Join us in spreading the message and involving<br></br> your friends and family in our movement</span>

                        <Link to="/contact" className='linkItem'><button type='cont' className='cn-btn'>Connect with us</button></Link>

                        <div className='footer-page'>
                            <div className='first-part'>
                                
                                <Link to="/contact" className='ftPage'>Contact</Link>
                                <Link to="/project" className='ftPage'>Projects</Link>
                
                            </div>
                            <div className='second-part'>
                                <Link to="/advertise" className='ftPage'>Get AquaBharat boxes. </Link>
                                <Link to="/distribute" className='ftPage'>Get AquaBharat bottles</Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className='Logos'>
                        <div className='f-three'>
                            < a href='https://www.instagram.com/aquabharatindia/' >  <FiInstagram className='ic' /></a>
                          
                            <a href='https://www.facebook.com/Aquabharatin'>  <ImFacebook  className='ic'/></a>
                             {/* <Link to="/facebook" className=''><img src={facbk} className='icc'/></Link>  */}
                             {/* <BsFacebook className='ic' />  */}

                             <a href='https://twitter.com/aquabharatindia'> <BsTwitter className='ic' /></a>
                            
                             <a href='https://www.youtube.com/@Aquabharat'> <BsYoutube className='ic' /></a>
                           
                           
                        </div>
                        
                    </div>
                </div>
                <div className='copyright'>
                    <span className='allright'>©2023 Copyright AquaBharat All Rights Reserved</span>
                </div>


            </footer>

    </div>
  )
}

export default Footer
