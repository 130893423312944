import React, { useState } from 'react';
import '../Components/CSS/Contact.css';
import con2 from '../Components/Images/Aluminum-Bottle-removebg-preview (1).png';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // You can add your form submission logic here
        console.log(formData);

        // Reset form fields after submission
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: ''
        });
    };

    return (
        <div>
            <div className='contact-form-main'>
                <div className='contact-form-part-two'>
                    <div className='contact-form'>
                        <h1>Contact Us</h1>
                        <form onSubmit={handleSubmit}>
                            <label className='contact-lable'>First Name *</label><br />
                            <input type='text' name='firstName' className='con-field1' value={formData.firstName} onChange={handleChange} required /><br />

                            <label className='contact-lable'>Last Name *</label><br /><br />
                            <input type='text' name='lastName' className='con-field1' value={formData.lastName} onChange={handleChange} required /><br />

                            <label className='contact-lable'>Email</label><br /><br />
                            <input type='email' name='email' className='con-field1' value={formData.email} onChange={handleChange} required /><br />

                            <label className='contact-lable'>Phone Number *</label><br /><br />
                            <input type='number' name='phoneNumber' className='con-field1' value={formData.phoneNumber} onChange={handleChange} required /><br />

                            <label className='contact-lable'>Subject *</label><br /><br />
                            <input type='text' name='subject' className='con-field1' value={formData.subject} onChange={handleChange} required /><br />

                            <label className='contact-lable'>Message *</label><br /><br />
                            <textarea name='message' className='con-textarea' value={formData.message} onChange={handleChange} required></textarea><br />

                            <button type='submit' className='con-send-btn'>Send</button>
                        </form>
                    </div>

                    <div className='form-bottle'>
                        <img src={con2} className='con-bottle' alt='Bottle' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
