import React from 'react'
import '../Components/CSS/Add.css'
import '../Components/CSS/Advertise.css'

const Add = () => {
  return (
    <div>
        <div className='adds-main'></div>
         <div className='add-session5'>
          <div className='get-price-form'>
            <h1>GET A PRICE</h1>

            <form className='price-form'>
              <input type='text' className='price-field1' required placeholder='Enter your name ' />
              <input type=' email' className='price-field1' required placeholder='Enter your email' />
              <input type='number' className='price-field1' required placeholder='Enter your phone number' />
              <input type=" text" className='price-field1' required placeholder='Enter your company name' />
              <input type='text' className='price-field1' required placeholder='Type of business' />
              <input type='text' className='price-field1' required placeholder='What do you want to adverties?' />
              <input type='text' className='price-field1' required placeholder='What is your budget' />
              <textarea className='price-field2' required placeholder='message'></textarea>
              <button type='submit' className='price-send-btn'>Send</button>
            </form>

          </div>
        </div>

      
    </div>
  )
}

export default Add
