import React, { useState } from 'react'
import "../Components/CSS/FAQ.css";


const FAQ = () => {

    const initialFAQState = [
        { question: 'How is AquaBharat free?', answer: ' AquaBharat is paid for by ads that are printed directly onto aluminum bottles and paper cartons. Ten cents per beverage is donated to charity to fight the global water crisis.', isOpen: false },
        { question: 'Will you ship AquaBharat to me?', answer: ' We are working around the clock to launch our free 12 pack program. Just pay for the shipping and the water is free and each water donates to charity too.', isOpen: false },
        { question: 'Why aluminum bottles and paper cartons?', answer: ' Looking carefully at the pros and cons of both aluminum bottles and paper cartons, we know neither is perfect – but both are far more sustainable than plastic bottles. Our long term goal is to cultivate, manufacture, fill, distribute, and recycle all our beverages in 100% hemp cartons from the same location.', isOpen: false },
        { question: 'Why BPA free?', answer: ' The majority of bottled water is contaminated with the chemical BPA. High levels of BPA have been linked to diseases such as hormonal disruption, diabetes, high blood pressure, infertility, breast cancer, and hazardous effects on the brain and behavior of infants and young children.', isOpen: false },
        { question: 'What charities do you donate to?', answer: '  We are currently contributing our donations to Well Aware and Charity: Water', isOpen: false },
        { question: 'How can I help AquaBharat?', answer: 'Please help us to spread the word by sharing our project on social media and with your friends and family.', isOpen: false },
      
       
       
 ];


 const initialFAQState2 = [
 { question: 'What are the minimum requirements to be a distributor?', answer: '​​Retail outlets need to commit to distributing a minimum of one pallet of AquaBharat per month.The location needs to be enticing enough to line up advertisers to cover the cost of the product/distribution.Each location will need to advertise that they are a AquaBharat distributor by posting a sign on their window and on their website if applies. We will also market your location for you. Individuals who wish to “officially” distribute AquaBharat must be hand selected by our team to ensure that they are a good fit.', isOpen: false },
 { question: 'Do distributers have to advertise?', answer: ' No, but that is the fastest way to get started.', isOpen: false },
 { question: 'How do I distribute AquaBharat?', answer: ' To distribute AqAquaBharat, please visit https://AquaBharat.io/distribute', isOpen: false },

       
 ];

 const initialFAQState3 = [
   
    { question: 'How much does it cost to advertise?', answer: ' It depends on several factors:  1. Aluminum bottles or paper cartons? 2. The number of units?3. Are we distributing the water or are you distributing the water?4. The location of distribution?  5. Do you have a graphic designer on staff or do you need us to design it for you? 6. Are you splitting the ad space with someone else? Aluminum bottles have a MOQ of a pallet which has 1,680 units. You can split the label’s real estate with a total of three brands.  Paper cartons have a MOQ of 10k units. You can split the label’s real estate with a total of eight brands.', isOpen: false },
    { question: 'How long does it take to place an advertising order?', answer: ' Aluminum Bottles- Roughly six weeks from design approval and payment. However, you should always place your order as far in advance as possible if you have a time sensitive project. Paper cartons- The current lead time for paper cartons is 4 months.', isOpen: false },
    { question: 'How does AquaBharat compare to other advertising mediums?', answer: 'AquaBharat is the best brand loyalty platform on Earth and there are unique opportunities to incorporate this new and exciting medium into most ad budgets.', isOpen: false },
    { question: 'How do I advertise?', answer: ' To advertise, please visit https://AquaBharat.io', isOpen: false },
    { question: 'How do I advertise with AquaBharat?', answer: ' To advertise with AquaBharat, please visit https://AquaBharat.io/advertise', isOpen: false },

    ];

    const initialFAQState4 = [
    { question: 'Can I use AquaBharat as a fundraising tool?', answer: ' We will create special partnerships for 501(c)(3) organizations or any other positive community outlets such as church groups, sports programs, and schools. Partners who are using AquaBharat as a fundraiser must be able to sell all the ad space themselves and we will contribute between ten and twenty five cents per beverage to the partner depending on the quantity and the price at which the product is sold.', isOpen: false },
    { question: 'Will you offer other free products in the future?', answer: ' Yes, we are launching a free supermarket / Amazon 2.0, and AquaBharat is simply our first product. We have created a handful of processes that will enable the free and profitable distribution of nearly every type of product that can be purchased in COSTCO today. This includes food, beverages, clothing, medicine, computers, transportation, and travel. Each category of product will donate a percentage of its revenue to a different charitable cause.', isOpen: false },
    { question: 'How do I invest?', answer: ' Please visit and fill out the form making sure to type a"investor" as a subject line. https://AquaBharat.io/contact-us', isOpen: false },
    ];


    const [faqItems, setFAQItems] = useState(initialFAQState);

    const toggleFAQ = (index) => {
        setFAQItems((prevItems) => {
            return prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }));
        });
    };

    const [faqItems2, setFAQItems2] = useState(initialFAQState2);

    const toggleFAQ2 = (index) => {
        setFAQItems2((prevItems) => {
            return prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }));
        });
    };
    const [faqItems3, setFAQItems3] = useState(initialFAQState3);

    const toggleFAQ3 = (index) => {
        setFAQItems3((prevItems) => {
            return prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }));
        });
    };
    const [faqItems4, setFAQItems4] = useState(initialFAQState4);

    const toggleFAQ4 = (index) => {
        setFAQItems4((prevItems) => {
            return prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }));
        });
    };


    return (
        <>
        <div className='faq-main'>

            <div className='mainfaq' id='FAQ'>
                <div className='faqtext'>
                    <h2>General</h2>
                </div>
                {faqItems.map((item, index) => (
                    <div key={index} className={`faq-item ${item.isOpen ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
                        <div className="question">
                            <span className="text">{item.question}</span>
                            <span className={`arrow ${item.isOpen ? 'open' : ''}`}>{item.isOpen ? '▼' : '▲'}</span>
                        </div>
                        {item.isOpen && (
                            <div className="answer">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='mainfaq' id='FAQ'>
                <div className='faqtext'>
                    <h2>Distribution</h2>
                </div>
                {faqItems2.map((item, index) => (
                    <div key={index} className={`faq-item ${item.isOpen ? 'open' : ''}`} onClick={() => toggleFAQ2(index)}>
                        <div className="question">
                            <span className="text">{item.question}</span>
                            <span className={`arrow ${item.isOpen ? 'open' : ''}`}>{item.isOpen ? '▼' : '▲'}</span>
                        </div>
                        {item.isOpen && (
                            <div className="answer">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='mainfaq' id='FAQ'>
                <div className='faqtext'>
                    <h2>Advertising</h2>
                </div>
                {faqItems3.map((item, index) => (
                    <div key={index} className={`faq-item ${item.isOpen ? 'open' : ''}`} onClick={() => toggleFAQ3(index)}>
                        <div className="question">
                            <span className="text">{item.question}</span>
                            <span className={`arrow ${item.isOpen ? 'open' : ''}`}>{item.isOpen ? '▼' : '▲'}</span>
                        </div>
                        {item.isOpen && (
                            <div className="answer">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='mainfaq' id='FAQ'>
                <div className='faqtext'>
                    <h2>Other</h2>
                </div>
                {faqItems4.map((item, index) => (
                    <div key={index} className={`faq-item ${item.isOpen ? 'open' : ''}`} onClick={() => toggleFAQ4(index)}>
                        <div className="question">
                            <span className="text">{item.question}</span>
                            <span className={`arrow ${item.isOpen ? 'open' : ''}`}>{item.isOpen ? '▼' : '▲'}</span>
                        </div>
                        {item.isOpen && (
                            <div className="answer">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            </div>
        </>

    )
}

export default FAQ;