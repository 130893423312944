import React from 'react'
import Advertise from '../src/Components/Advertise'
import Distribute from './Components/Distribute'
import FAQ from '../src/Components/FAQ'
import Contact from './Components/Contact'
import Projects from './Components/Projects'
import Footer from './Components/Footer'
import Home from './Components/Home'
import { Route, Routes } from 'react-router-dom'
import Navbar from './Components/Navbar'
import DistFt from './Components/DistFt'
import ScrollToTop from './Components/ScrollToTop'
import Add from './Components/Add'

const App = () => {
  return (
   <>
   {/* <Advertise/> */}
   {/* <FAQ/> */}
   {/* <Distribute/> */}
   {/* <Contact/> */}
   {/* <Projects/> */}
   {/* <Footer/> */}
   <ScrollToTop/>

<Navbar/>

   <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/advertise' element={<Advertise/>}/>
    <Route path='/dist' element={<Distribute/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/faq' element={<FAQ/>}/>
    <Route path='/project' element={<Projects/>}/>
    <Route path='/distribute' element={<DistFt/>}/>
    <Route path='adds' element={<Add/>}/>
   </Routes>
   <Footer/>
   
   </>
  )
}

export default App
