// Distribute.js
import React, { useState } from 'react';
import '../Components/CSS/Distribute.css';
import energybtl from '../Components/Image/energy-btl.jpeg';

const Distribute = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [distributionPointAddress, setDistributionPointAddress] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');
    const [ageRange, setAgeRange] = useState('');
    const [monthlyDistribution, setMonthlyDistribution] = useState('');
    const [loadingDock, setLoadingDock] = useState('');
    const [whyDistribute, setWhyDistribute] = useState('');
    const [agreeToShare, setAgreeToShare] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (
            !firstName || !lastName || !email || !phoneNumber || !companyName || !businessType ||
            !distributionPointAddress || !ageRange || !monthlyDistribution || !loadingDock || !whyDistribute || !agreeToShare
        ) {
            alert('Please fill in all required fields');
            return;
        }
    }

    return (
        <div>
            <div className='dist-session1'>
                <div className='dist-main-head'>
                    <h1>Distribute AquaBharat</h1>
                    <span>We have a range of options for you, whether it's for an event or your business.</span>
                </div>

                <div className='dist-energy-bottle'>
                    <img src={energybtl} className='energy-btl' alt='Energy Bottle'/>
                </div>
                <div className='distribute-form'>
                    <form className='dist-form' onSubmit={handleSubmit}>
                        <h1>Distribute AquaBharat</h1>
                        <label className='dist-lable'>Enter your first name</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Enter your last name</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Enter your email</label>
                        <input
                            type='email'
                            className='dist-field1'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Enter your phone number</label>
                        <input
                            type='number'
                            className='dist-field1'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Enter your company name</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Your business type</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={businessType}
                            onChange={(e) => setBusinessType(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Distribution point address</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={distributionPointAddress}
                            onChange={(e) => setDistributionPointAddress(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Shipping address if it is different than the distribution point</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={shippingAddress}
                            onChange={(e) => setShippingAddress(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Age range</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={ageRange}
                            onChange={(e) => setAgeRange(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>How many beverages do you want to distribute monthly?</label>
                        <input
                            type='text'
                            className='dist-field1'
                            value={monthlyDistribution}
                            onChange={(e) => setMonthlyDistribution(e.target.value)}
                            required
                            placeholder=''
                        />

                        <label className='dist-lable'>Do you have an elevated loading dock to receive a shipment?</label>
                        <label className='radio-lable'>
                            <input
                                type='radio'
                                className='dist-radio'
                                value='yes'
                                checked={loadingDock === 'yes'}
                                onChange={() => setLoadingDock('yes')}
                            />
                            YES
                        </label>
                        <label className='radio-lable'>
                            <input
                                type='radio'
                                className='dist-radio'
                                value='no'
                                checked={loadingDock === 'no'}
                                onChange={() => setLoadingDock('no')}
                            />
                            No
                        </label>

                        <label className='dist-lable'>Why do you want to distribute AquaBharat?</label>
                        <textarea
                            className='dist-textarea'
                            value={whyDistribute}
                            onChange={(e) => setWhyDistribute(e.target.value)}
                            required
                            placeholder=''
                        ></textarea>

                        <label className='check-lable'>
                            <input
                                type='checkbox'
                                className='dist-checkbox'
                                checked={agreeToShare}
                                onChange={() => setAgreeToShare(!agreeToShare)}
                                required
                            />
                            I agree to share this information with potential advertisers
                        </label>

                        <button type='submit' className='dist-send-btn'>
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Distribute;
