import React, { useState } from 'react';
import Banner from '../Components/Images/passorg.jpg';
import piggy from '../Components/Images/piggi.jpg';
import drop from '../Components/Images/Waterdrop.jpeg';
import LiveHands from '../Components/Images/Heart.png';
import Octopus from '../Components/Images/Octo.jpeg';
import Scbottle from '../Components/Images/ezgif.com-video-to-gif.gif';
import boyimg from '../Components/Images/drink-w.jpg';
import PlasticBottle from '../Components/Images/p-btl-removebg-preview.png';
import '../Components/CSS/Home.css';

const Home = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        city: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // You can add your form submission logic here
        console.log(formData);

        // Reset form fields after submission
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            city: ''
        });
    };

    return (
        <div>
            <div className="Mainbanner">
                <img src={Banner} alt="" className="Banner" />
            </div>

            <div className="MoreInfo">
                <div className="MoreinfoFr">
                    <img src={piggy} alt="" className="Homesrc" />
                    <h2 className='saveMoneyH'>Preserve lives</h2>
                    <p className='saveMoneyP'>We donate ten cents from every beverage to charity, supporting the construction of water wells for those in need.</p>
                </div>

                <div className="moreInfozFr moreinfo2">
                    <img src={Octopus} alt="" className="Homesrc octo" />
                    <h2 className="saveMoneyH save2">Earth-conscious</h2>
                    <p className="saveMoneyP">We are dedicated to achieving a net positive status by the year 2030 we are striving to create a better world for all.</p>
                </div>
            </div>

            <div className="MoreInfo MoreInfo2">
                <div className="MoreinfoFr">
                    <img src={LiveHands} alt="" className="Homesrc" />
                    <h2 className='saveMoneyH'>Save Money</h2>
                    <p className='saveMoneyP'>The price of bottled water is a staggering 2000 times higher than that of tap water.</p>
                </div>

                <div className="moreInfozFr">
                    <img src={drop} alt="" className="Homesrc" />
                    <h2 className="saveMoneyH">High-quality H2O</h2>
                    <p className="saveMoneyP">Our product, AquaBharat, is sourced from natural springs, and our containers are all BPA-free.</p>
                </div>
            </div>

            <div className="BrandInfo">
                <div className="ScrollbottleTxt">
                    <p>AquaBharat, an inventive advertising platform, utilizes premium spring water as a unique advertising medium, <b>all while emphasizing philanthropy and sustainability.</b> We donate ten cents from each beverage to combat the global water crisis. Our mission is to have just 10% of Americans choose AquaBharat, ultimately resolving the global water crisis permanently.</p>
                </div>
                <div className="ScrollBtl">
                    <img src={Scbottle} alt="" className="ScrollBtl" />
                </div>
            </div>

            <div className="BrandInfo3">
                <div className="ScrollBtl2">
                    <img src={boyimg} alt="" className="schollboy2" />
                </div>
                <div className="ScrollbottleTxt2">
                    <h2>Our partnership is with WellWare</h2>
                    <p>We donate fifteen cents for each drink to AquaLife, a charitable organization based in Houston, committed to building water wells in West Africa. Every 200 AquaBharat's we distribute generates enough funds to ensure one individual in need has access to a secure water supply for their lifetime.</p>
                </div>
            </div>

            <div className="PlasticBottle">
                <div className="Sometext">
                    <h2>Plastic bottles are never used by us.</h2>
                    <p>We offer AquaBharat in aluminum bottles and natural spring water cartons to avoid using plastic bottles. This decision reflects our dedication to preserving water quality and minimizing environmental impact.</p>
                </div>
                <div className="ImagesContainer">
                    <img src={PlasticBottle} alt="" className="PlasticBottlesimg" />
                </div>
            </div>

            <div className='homeform'>
                <div className='subscribe-home'>
                    <h1>Get your AquaBharat by subscribing</h1>
                    <form onSubmit={handleSubmit} className='sub-form'>
                        <label className='home-lable'>First Name *</label><br />
                        <input type='text' name='firstName' className='home-field1' value={formData.firstName} onChange={handleChange} required /><br />

                        <label className='home-lable'>Last Name</label><br />
                        <input type='text' name='lastName' className='home-field1' value={formData.lastName} onChange={handleChange} required /><br />

                        <label className='home-lable'>Email</label><br />
                        <input type='email' name='email' className='home-field1' value={formData.email} onChange={handleChange} required /><br />

                        <label className='home-lable'>Mobile</label><br />
                        <input type='number' name='mobile' className='home-field1' value={formData.mobile} onChange={handleChange} required /><br />

                        <label className='home-lable'>City *</label><br />
                        <input type='text' name='city' className='home-field1' value={formData.city} onChange={handleChange} required /><br />

                        <button type='submit' className='home-send-btn'>Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Home;
