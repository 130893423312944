import React, { useState } from 'react';
import '../Components/CSS/Advertise.css';
import add1 from './Images/best-btl-removebg-preview.png';
import add2 from '../Components/Images/blc-btl.png';
import msgbox from './Images/msgbox.webp';
import doller from './Images/doller.webp';
import plane from './Images/plane.webp';
import saving from './Images/saving.webp';
import gif from './Images//ezgif.com-video-to-gif.gif';
import { Link } from 'react-router-dom';

const Advertise = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    business: '',
    advertise: '',
    budget: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (!formData.name || !formData.email || !formData.phone || !formData.company || !formData.business || !formData.advertise || !formData.budget || !formData.message) {
      alert('Please fill out all fields.');
    } else {
      // Form submission successful
      alert('Form submitted successfully.');
      // Reset form fields
      setFormData({
        name: '',
        email: '',
        phone: '',
        company: '',
        business: '',
        advertise: '',
        budget: '',
        message: ''
      });
    }
  };

  return (
    <div>
      <div className='add-page'>
        <div className='add-session1'>
          <div className='add-img1'>
            <img src={add1} className='add' alt='Advertise Image' />
          </div>
          <div className='brd-info'>
            <b>PLACE YOUR BRAND IN ALL HANDS</b>
            <p>Acquire significant connections and employ the foremost brand loyalty platform in the advertising industry.</p>
            <Link to='/adds'><button className='get-rs'>Get Price</button></Link>
          </div>
        </div>

        <div className='add-session2'>
          <div className='pr-info'>
            <b>Our product is like a blank slate.</b>
            <p>We stand in contrast to typical advertising as we prioritize non-intrusiveness. Rather than eliciting cringes or prompting audiences to seek cover, we aim to bring smiles to their faces. It is in this state of happiness that they become eager to support your brand.</p>
            <div className='product-feature'>
              <div className='frt-info'>
                <div className='frt1'>
                  <b>Utilize QR Codes</b>
                  <p>For internet connectivity of various content, such as websites, coupons, and videos.</p>
                </div>
                <div className='frt1'>
                  <b>Utilize QR Codes</b>
                  <p>For internet connectivity of various content, such as websites, coupons, and videos.</p>
                </div>
              </div>
              <div className='frt-info'>
                <div className='frt1'>
                  <b>Utilize QR Codes</b>
                  <p>For internet connectivity of various content, such as websites, coupons, and videos.</p>
                </div>
                <div className='frt1'>
                  <b>Utilize QR Codes</b>
                  <p>For internet connectivity of various content, such as websites, coupons, and videos.</p>
                </div>
              </div>
            </div>
            <Link to='/adds'><button className='get-rs'>Get Price</button></Link>
          </div>
          <img src={add2} className='add2' alt='Advertise Image' />
        </div>

        <div className='add-session3'>
          <div className='black-bar-content'>
            <div className='add-gif'>
              <img src={plane} className='plane-gif' alt='Advertise Image' />
              <h3>29%</h3>
              <p>Profitability assessment.</p>
            </div>
            <div className='add-gif'>
              <img src={saving} className='plane-gif' alt='Advertise Image' />
              <h3>Priceless</h3>
              <p>Safeguarding lives and supporting the environment.</p>
            </div>
            <div className='add-gif'>
              <img src={msgbox} className='plane-gif' alt='Advertise Image' />
              <h3>10X</h3>
              <p>Generate greater impressions compared to direct mail.</p>
            </div>
            <div className='add-gif'>
              <img src={doller} className='plane-gif' alt='Advertise Image' />
              <h3>$2.50</h3>
              <p>Costs less for every ten impressions than direct mail.</p>
            </div>
          </div>
        </div>

        <div className='add-session4'>
          <div className='bottle-video'>
            <img src={gif} className='btl-video' alt='Advertise Image' />
            <h5>Paper Carton</h5>
            <p>16.9 fl oz / 500ml</p>
            <Link to='/adds'><button className='get-rs'>Get Price</button></Link>
          </div>
          <div className='bottle-video'>
            <img src={gif} className='btl-video' alt='Advertise Image' />
            <h5>Alumi-Tec Bottle</h5>
            <p>16 fl oz / 474ml</p>
            <Link to='/adds'><button className='get-rs'>Get Price</button></Link>
          </div>
        </div>

        <div className='add-session5'>
          <div className='get-price-form'>
            <h1>GET A PRICE</h1>
            <form className='price-form' onSubmit={handleSubmit}>
              <input type='text' className='price-field1' name='name' value={formData.name} onChange={handleChange} required placeholder='Enter your name' />
              <input type='email' className='price-field1' name='email' value={formData.email} onChange={handleChange} required placeholder='Enter your email' />
              <input type='number' className='price-field1' name='phone' value={formData.phone} onChange={handleChange} required placeholder='Enter your phone number' />
              <input type='text' className='price-field1' name='company' value={formData.company} onChange={handleChange} required placeholder='Enter your company name' />
              <input type='text' className='price-field1' name='business' value={formData.business} onChange={handleChange} required placeholder='Type of business' />
              <input type='text' className='price-field1' name='advertise' value={formData.advertise} onChange={handleChange} required placeholder='What do you want to advertise?' />
              <input type='text' className='price-field1' name='budget' value={formData.budget} onChange={handleChange} required placeholder='What is your budget' />
              <textarea className='price-field2' name='message' value={formData.message} onChange={handleChange} required placeholder='Message'></textarea>
              <button type='submit' className='price-send-btn'>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertise;
